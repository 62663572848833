
.template-wrapper {
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 60px;
    }
  }
}
.main-title-text {
  font-size: 19px;
  font-weight: 800;
  color: #606266;
}
.create-content {
  margin: 60px 0;

  .success-content {
    margin-top: 155px;
    text-align: center;
    display: flex;
    justify-content: center;
    .iconfont {
      color: #1fd18c;
      font-size: 52px;
    }
    .text {
      font-size: 24px;
      margin: 20px 0 0 17px;
      font-weight: bold;
    }
  }
}
.bottom-btn {
  text-align: center;
}
.module-data {
  margin: 24px 0;
  ::v-deep .el-form {
    width: 800px;
  }
}
